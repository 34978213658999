<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>

      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>

    </breadcrumb-button>
    <!--Filters-->
    <filter-card v-model="filter" v-bind:fields="filterFields" search />
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50 d-flex">
        <h5>{{ $t('menu.account') }}</h5>
        <div>
          <transaction-toggle class="mb-1" v-model="transactionType"/>
        </div>
      </b-card-header>
      <b-card-body>
        <account-table ref="accountTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="false"
                       :lazy="true"
         :trans-type="transactionType"/>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="account" v-model="columns"
                   v-bind:columns-names="columnsNames"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import AccountSearchRequest from '@/model/account/AccountSearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import Account from '@/model/account/Account';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import FilterCard from '@/components/widget/FilterCard';
import AccountFilterFields from '@/model/filter/AccountFilterFields';
import AccountTable from '@/components/tables/AccountTable';

export default {
  name: 'AccountView',
  components: {
    AccountTable,
    FilterCard,
    ColumnsModal,
    BreadcrumbButton,
  },
  mixins: [mixinBase, mixinPermissions],
  data() {
    return {

      transactionType: 'deposits',
      columnsNames: Object.keys(Account()),
      filter: AccountSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    };
  },
  watch: {

  },
  async created() {
    this.filterFields = AccountFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('account', ['']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
