export default function () {
  return [

    {
      key: 'id',
      label: 'account'
    },
    {
      key: 'orgIds',
      type: 'org',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'name',
      label: 'clientName'
    },
    {
      key: 'email',
      label: 'clientEmail'
    },

    {
      type: 'checkboxGroup',
      fields: [
     /*    {
          key: 'active',
          label: 'active',
          type: 'checkbox'
        }, */
        {
          key: 'isDemo',
          label: 'demo',
          type: 'checkbox'
        },
        {
          key: 'marginCall',
          label: 'marginCall',
          type: 'checkbox'
        }
      ]
    }
  ];
}
