<template>
  <div v-bind:key="refreshKey">
    <b-table responsive
             v-bind:items="accounts"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             hover
             v-bind:busy="loading"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >

      <template #cell(clientId)="data">
        <ClientCell @open-client-account="handleOpenClientAccount" :client="clients[data.value]"/>
      </template>


      <template #cell(registrationDate)="data">
        {{ data.value| momentDateTime }}
      </template>

      <template #cell(marginCall)="data">
        <span :style="{ color: data.value!=null&& data.value?  '#c53838' : null }"
        >  {{ data.value != null && data.value ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(active)="data">
        <span :style="{ color: data.value > 0 ? '#4ebd52' : '#c53838' }">  {{ data.value ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(isDemo)="data">
        <span>  {{ data.value ? 'Yes' : 'No' }}</span>
      </template>
      <template #cell(orgId)="data">
        {{ getOrganizationNameById(data.value) }}
      </template>
      <template #cell(balance)="data">
        <span :style="{ color: data.value > 0 ? '#4ebd52' : '#c53838' }"> {{ convertToEURO(data.value) }}</span>
      </template>
      <template #cell(credit)="data">
        <span :style="{ color: data.value > 0 ? '#4ebd52' : '#c53838' }"> {{ convertToEURO(data.value) }}</span>
      </template>
      <template #cell(balance)="data">
        <span :style="{ color: data.value > 0 ? '#4ebd52' : '#c53838' }"> {{ convertToEURO(data.value) }}</span>
      </template>


      <template #cell(margin)="data">
        <span> {{ convertToEURO(data.value) }}</span>
      </template>


    </b-table>


    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size"  @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
          <b-select-option v-bind:value="250">250</b-select-option>
          <b-select-option v-bind:value="500">500</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.accounts') }}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientCell from '@/components/partial/clients/ClientCell';
import UserSelectBox from '@/components/widget/select/UserSelectBox';

export default {
  name: 'AccountTable',
  components: {
    ClientCell,
    UserSelectBox,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    accounts: [],
    initialLoad: true, // Add this flag
    comment: '',
    title: '',
    selectedItem: {}, // Store the selected item
    clients: {},
  }),
  props: {
    transType: {
      required: true
    },
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // Skip the initial load call
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        }

        this.pagination.page = 1;
        console.log('refresh was called() from watch handler');
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 10);
    if (!this.lazy) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById','getUserFullName','getAffiliateNameById']),

    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        return {
          key: column.key,
          label: $this.$t(`columns.${column.key}`),
          sortable: true,
          active: column.enable,
        };
      });

      return fields.filter(f => f.active === true);
    },

  },
  methods: {
    ...mapActions('account', ['getAllAccounts']),

    async refresh() {
      let $this = this;

      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }

      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllAccounts(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.accounts') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.accounts = result.data['accounts'];
      this.clients = result.data['clients'].reduce((map, obj) => (map[obj.id] = obj, map), {});
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    },
  }
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.overflow-auto {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
