export default function (other) {
  other = other || {};
  return {
    id: other.id || null,
    orgId: other.orgId || null,
    clientId: other.clientId || null,
    type: other.type || null,
    active: other.active || null,
    registrationDate: other.registrationDate || null,
    lastConnectionDate: other.lastConnectionDate || null,
    defaultLeverage: other.defaultLeverage || null,
    lastIp: other.lastIp || null,
    balance: other.balance || null,
    credit: other.credit || null,
    isDemo: other.isDemo || null,
    margin: other.margin || null,
    marginStatus: other.marginStatus || null,
    marginCall: other.marginCall || null,
    currency: other.currency || null,
    lastUpdate: other.lastUpdate || null,
    totalOpenTrades: other.totalOpenTrades || null,
  };
}
